@font-face {
    font-family: 'Century Gothic';
    src: url('../fonts/CenturyGothic.eot');
    src: url('../fonts/CenturyGothic.eot?#iefix') format('embedded-opentype');
    src: url('../fonts/CenturyGothic.woff') format('woff');
    src: url('../fonts/CenturyGothic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; }
@font-face {
    font-family: 'Century Gothic';
    src: url('../fonts/CenturyGothic-Bold.eot');
    src: url('../fonts/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype');
    src: url('../fonts/CenturyGothic-Bold.woff') format('woff');
    src: url('../fonts/CenturyGothic-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal; }
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype');
    src: url('../fonts/Roboto-Light.woff') format('woff');
    src: url('../fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal; }
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype');
    src: url('../fonts/Roboto-Regular.woff') format('woff');
    src: url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; }
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype');
    src: url('../fonts/Roboto-Medium.woff') format('woff');
    src: url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal; }
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.eot');
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype');
    src: url('../fonts/Roboto-Bold.woff') format('woff');
    src: url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal; }
