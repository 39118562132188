// Normalize Styles
@import "node_modules/normalize.css/normalize";
@import "libs/bootstrap-grid.min";
@import "fonts";

$PrimFont : 'Century Gothic';
$SecFont : 'Roboto';

$PrimCol : #D24D4D;
$TextCol : #393939;
$TextCol2: #666666;


.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $PrimCol;
  z-index: 999;
  transition: all 1s cubic-bezier(.475,.425,0,.995);
  &.hide {
    top: -100%; } }


.loader-icon {
  perspective: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  &:before {
    content: "";
    position: absolute;
    left: -25px;
    top: -25px;
    width: 50px;
    height: 50px;
    background-color: white;
    animation: flip 1.5s cubic-bezier(.42,0,.58,1) infinite; } }

@keyframes flip {
  0% {
    transform: rotate(0); }
  50% {
    transform: rotateY(180deg); }
  100% {
    transform: rotateY(180deg)  rotateX(180deg); } }


// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

// Pseudo bootstrap

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.position-relative {
  position: relative; }




// Page styles
body {
  font-family: $SecFont;
  color: $TextCol; }

.content {
  overflow: hidden; }

.white {
  color: #fff; }

.grey {
  color: $TextCol2; }

.primary-bg {
  background-color: $PrimCol; }

.section-padding {
  padding: 80px 0; }


// Text default -------------------

.text-small {
  font-size: 12px;
  margin: 13px 0; }

.text {
  font-size: 14px;
  margin: 13px 0; }

.text-middle {
  font-size: 16px;
  margin: 13px 0; }

.text-big {
  font-size: 18px;
  margin: 13px 0; }

.light {
  font-weight: 300; }

.medium {
  font-weight: 600; }

.bold {
  font-weight: bold; }
// Text default end ----------------


.def-h {
  font-family: $PrimFont;
  font-size: 26px;
  margin: 0 0 25px; }

.list, ul {
  list-style: none;
  padding: 0;
  li {
    list-style: none;
    padding-left: 20px;
    margin-bottom: 12px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $TextCol2;
      border-radius: 100%;
      top: 4px;
      left: 0; } } }

a, a:visited {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease-out; }

a:hover {
  color: lighten($PrimCol, 30); }

.bg-element {
  position: absolute;
  background-image: url('../images/dot.png');
  opacity: .5;
  z-index: -1; }

.bg-element_1 {
  top: 40px;
  right: 0px;
  height: 150px;
  width: 220px;
  background-size: auto calc(150px / 5); }
.bg-element_2 {
  bottom: 0;
  left: 0;
  height: 150px;
  width: 210px;
  background-size: auto calc(150px / 5); }
.bg-element_3 {
  top: 40px;
  right: 0px;
  height: 450px;
  width: 90px;
  background-size: auto calc(450px / 15); }
.bg-element_4 {
  bottom: 80px;
  left: 0;
  height: 150px;
  width: 330px;
  background-size: auto calc(150px / 5); }


// Sections

.start-section {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black,.7);
    z-index: -1; } }

.logos {
  position: absolute;
  width: 100%;
  top: 30px; }

.logo-img {
  height: 38px;
  img {
    height: 100%; }
  &.right {
    float: right; } }

.start-text {
  max-width: 720px;
  padding: 35px 20px 22px 35px;
  background-image: url('data:image/svg+xml;utf8,<svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V15C0 15.2181 0.0174561 15.4321 0.0510559 15.6408L0.0206451 61C0.019165 63.2091 1.80882 65.0012 4.01796 65.0027L15.018 65.01C17.2271 65.0115 19.0192 63.2219 19.0206 61.0127L19.0488 19H61C63.2091 19 65 17.2091 65 15V4C65 1.79086 63.2091 0 61 0H4Z" fill="white"/></svg>'), url('data:image/svg+xml;utf8,<svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M61 65.01C63.2091 65.01 65 63.2192 65 61.01V50.01C65 49.7919 64.9825 49.5779 64.949 49.3693L64.9794 4.01006C64.9808 1.80093 63.1912 0.00886536 60.982 0.00738525L49.982 7.62939e-06C47.7729 -0.00147247 45.9808 1.78819 45.9794 3.99733L45.9512 46.01L4 46.01C1.79086 46.01 0 47.8009 0 50.01V61.01C0 63.2192 1.79086 65.01 4 65.01L61 65.01Z" fill="white"/></svg>');
  background-repeat: no-repeat, no-repeat;
  background-size: 60px, 60px;
  background-position: left top, right bottom; }

.start-h {
  font-weight: 300;
  font-size: 32px;
  margin: 0; }

.feature-icon {
  height: 100px;
  img {
    height: 100%;
    max-width: 100%; } }



.laptop-img {
  width: 100%; }


.button, body form .button {
  display: inline-block;
  border: 1px solid $PrimCol;
  padding: 10px 15px;
  background-color: transparent;
  color: $PrimCol;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  text-decoration: none;
  line-height: 14px;
  vertical-align: middle;
  transition: all .5s cubic-bezier(.475,.425,0,.995);
  cursor: pointer;
  &:hover {
    color: white;
    background-color: $PrimCol;
    path {
      stroke: white; } }

  &_filled {
    background-color: $PrimCol;
    color: white;
    &:hover {
      background-color: transparent;
      color: $PrimCol; } }
  &_filled-inversed {
    border-color: white;
    background-color: white;
    color: $PrimCol;
    &:hover {
      border-color: $PrimCol;
      background-color: $PrimCol;
      color: white; } } }

.arrow-icon {
  display: inline-block;
  height: 14px;
  vertical-align: middle;
  margin-left: 10px;
  path {
    transition: all .5s cubic-bezier(.475,.425,0,.995); } }



.desc-card {
  background-color: #fff;
  box-shadow: 1px 1px 11px rgba(26, 26, 26, 0.1);
  border-radius: 10px;
  margin: 0 20px;
  padding: 30px 30px 50px;
  height: 100%; }


.red-line {
  height: 1px;
  width: 98%;
  background-color: $PrimCol;
  margin: 5px auto; }


.support-h {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
  span {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #FFFEFE;
      bottom: -1px;
      left: 0;
      opacity: .3; } } }

.support-item {
  width: 90px;
  height: 70px;
  border-radius: 6px;
  padding: 5px 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center; }
  &.border {
    border: 1px solid rgba(white, .3); } }

.vertical-gutter {
  height: 60px; }

.problem-card {
  padding: 0 30px; }

.problem-circle {
  position: relative;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  height: 190px;
  font-size: 50px;
  font-weight: bold;
  &:before {
    content: '';
    position: absolute;
    width: 190px;
    height: 190px;
    border: 30px solid $PrimCol;
    border-radius: 100%; } }

.howworks-card {
  margin-bottom: 40px; }

.howworks-num {
  font-size: 30px;
  padding-bottom: 10px;
  position: relative;
  color: $PrimCol;
  font-weight: 300;
  margin-top: 60px;
  &:after {
    content: '';
    position: absolute;
    width: 55px;
    background-color: #393939;
    height: 1px;
    left: 0;
    bottom: 0; } }


.promo-card {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  padding: 17px 20px 55px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#8E3333,.8);
    z-index: -1; } }


.def-h.small {
  font-size: 20px; }

.monitor-video {
  position: absolute;
  height: 100%;
  width: 45%;
  right: 0;
  top: 0;
  text-align: right; }

.monitor-img {
  height: 100%; }


.footer {
  padding: 85px 0 45px; }

.footer-contacts {
  a {
    display: block;
    margin-bottom: 6px;
    font-size: 14px;
    transition: all .3s ease-out;
    &:hover {
      transform: translateX(5px); } } }

.footer-text {
  position: relative;
  padding-right: 135px; }

.cisco-logo {
  position: absolute;
  width: 120px;
  bottom: 0;
  right: 0;
  img {
    width: 100%; } }

.big-bottom-padding {
  padding-bottom: 150px; }


.video-laptop {
  position: relative; }

.play-btn {
  position: absolute;
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  background-image: url('../images/play-button.svg');
  background-size: contain;
  background-repeat: no-repeat;
  top: 40%;
  left: calc(50% - 25px);
  transition: all .3s cubic-bezier(.475,.425,0,.995);
  cursor: pointer;
  &:hover {
    transform: scale(1.1); }
  &:focus {
    outline: none;
    transform: scale(1.05); }
  &_2 {
    top: 35%; } }


.control-card {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  margin: 0 15px 30px;
  width: calc((100% - 150px) / 5);
  text-align: center;
  padding: 50px 10px;
  color: $PrimCol;
  font-weight: 300;
  box-shadow: 1px 1px 11px rgba(26, 26, 26, 0.1);
  border-radius: 10px;
  font-size: 14px;
  position: relative;
  background-color: #fff;
  b {
    font-weight: bold; }

  &:before, &:after {
    content: '';
    position: absolute;
    background-color: #f4f3f3;
    height: 4px;
    width: 20%;
    border-radius: 10px;
    left: 40%; }
  &:before {
    top: 30px; }
  &:after {
    bottom: 30px; } }



// POPUPs

.popup {
  display: none;
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: rgba(black,.7);
  top: 0;
  left: 0; }

.popop-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center; }

.popup-close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  background-image: url('../images/close-button.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: all .4s cubic-bezier(0.5, 0, 0, 1);
  transform: translateY(-20px) scaleY(1.05);
  opacity: 0; }

.consultation-form {
  max-width: 360px;
  width: 98%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 50px;
  transition: all .4s cubic-bezier(0.5, 0, 0, 1);
  transform: translateY(-20px) scaleY(1.05);
  opacity: 0; }

.popup.active {
  .consultation-form, .video-container, .popup-close {
    transform: none;
    opacity: 1; } }

.def-field, body form .def-field {
  display: block;
  font-family: $SecFont;
  font-size: 12px;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid rgba(#494949, .6);
  margin-bottom: 20px;
  width: 100%; }


.checkbox {
  text-align: left;
  padding-left: 30px;
  display: block;
  position: relative;
  font-size: 11px;
  margin-bottom: 20px;
  cursor: pointer;
  z-index: 1;
  input {
    display: none;
    &:checked + .checkbox-icon:before {
      transform: scale(1);
      opacity: 1; } }

  &.warning {
    &:before {
      content: '';
      position: absolute;
      width: 110%;
      height: 120%;
      background-color: #fff9e1;
      border-radius: 5px;
      top: -10%;
      left: -5%;
      z-index: -1; } }

  .checkbox-icon {
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    border: 1px solid rgba(#494949, .6);
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 3px;
      background-color: $PrimCol;
      top: 2px;
      left: 2px;
      transform: scale(.8);
      opacity: 0;
      transition: all .3s cubic-bezier(0.5, 0, 0, 1); } }

  a {
    color: $PrimCol;
    &:hover {
      text-decoration: underline; } } }

.video-container {
  max-width: 1000px;
  width: 98%;
  transition: all .4s cubic-bezier(0.5, 0, 0, 1);
  transform: translateY(-20px) scaleY(1.05);
  opacity: 0;
  background-color: black;

  .video-inner {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 25px; }
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; } }


span.wpcf7-not-valid-tip {
  font-size: 12px;
  transform: translateY(-15px); }

div.wpcf7-response-output {
  font-size: 12px;
  line-height: 1.4;
  border-radius: 5px;
  padding: 10px 5px; }

// Animate

.crop-animate {
  overflow: hidden;
  .crop-animate-inner {
    transition: all 1.2s cubic-bezier(.475,.425,0,.995);
    transform: translateY(110%);
    padding: 1px 0; }
  &.active .crop-animate-inner {
    transform: translateY(0%); } }







@media (max-width: 1199px) {

  .desc-card {
    margin: 0; } }



@media (max-width: 991px) {

  .def-h {
    font-size: 20px; }

  .section-padding {
    padding: 45px 0; }

  .text-big {
    font-size: 17px; }
  .text-middle {
    font-size: 15px; }

  .control-card {
    width: calc((100% - 90px) / 3); }

  .problem-card {
    padding: 0; }

  .def-h.small {
    font-size: 18px; }

  .footer-text {
    padding-right: 0; }

  .cisco-logo {
    bottom: calc(100% + 10px);
    right: -10px; } }


@media (max-width: 767px) {

  .start-h {
    font-size: 21px;
    margin-bottom: 10px; }

  .start-text {
    width: 90%;
    background-size: 30px, 30px;
    padding: 20px 20px 10px 20px; }

  .start-section {
    padding: 0 15px; }

  .logos {
    top: 15px; }

  .logo-img {
    height: 28px; }

  .feature-icon {
    height: 50px; }

  .text-medium {
    font-size: 14px; }

  .text {
    font-size: 13px; }

  .video-laptop {
    margin: 20px 0; }

  .support-item {
    margin-bottom: 15px; }

  .control-card {
    width: calc((100% - 60px) / 2);
    &:last-child {
      margin-bottom: 5px; } }

  .problem-card {
    max-width: 200px;
    margin: 0 auto 15px; }

  .howworks-num {
    margin-top: 10px; }

  .howworks-card {
    margin-bottom: 10px; }

  .popup-close {
    right: 10px;
    width: 20px;
    top: 10px; } }


